.content-container {
    background-color: rgb(255, 255, 255);
    width: 60vw;
    margin: 0 auto 3em auto;
    min-height: 10em;
    border-radius: 1em;
    padding: 1em 5% 3em 5%;
    box-shadow: rgba(0, 0, 0, 0.3) 1em 1em 1em;
    color: rgb(85, 85, 85);
    line-height: 2em;
    border: solid black 2px;
    overflow-wrap: break-word;
    position: relative;
}

p {
    font-size: 1.1em;
    margin: 1em 0;
}

li {
    font-size: 1.1em;
}

h1 {
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 0.3em;
    margin-bottom: 1.5em;
    text-shadow: 2px 2px rgb(181 220 236)
}

h2 {
    margin-top: 2em;
    text-align: center;
}

h3 {
    text-align: center;
    font-size: 1.1em;
    margin: 0;
}

.caption {
    font-weight: normal;
    text-align: center;
    font-style: italic;
    margin: 2em 0.5em 0.5em 0.5em;
    font-size: 1.1em;
}

iframe {
    width: 100%;
    aspect-ratio: 16/9;
}

video {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

code {
    background-color: rgb(75, 75, 75);
    border-radius: 0.2em;
    padding: 0.2em;
    color: rgb(211, 211, 211);
    margin: 0 0.1em;
    font-size: 0.8em;
    overflow: scroll;
}

pre.normal-pre {
    background-color: rgb(222, 222, 222);
    color: black;
    display: block;
    border-left: solid rgb(195, 195, 195) 0.5em;
    padding: 0.5em 0.5em 0.5em 1em;
    line-height: 1.5em;
    margin: 0;
}

pre code {
    background-color: rgb(75, 75, 75);
    color: rgb(211, 211, 211);
    display: block;
    padding: 1.5em 2em;
    line-height: 1.5em;
    margin: 2em 0;
    font-size: 1em;
}

img {
    display: block;
    margin: 3em auto;
    max-width: 100%;
}

.styled-image {
    border-radius: 2em;
    box-shadow: 1em 1em 1em rgb(176, 176, 176);
}

.centered {
    text-align: center;
}

.centered-justified p {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align-last: center;
}

svg {
    overflow: visible;
}

@media screen and (max-width: 1250px) {
    .content-container {
        width: 80vw;
    }
}