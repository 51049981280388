.header-item {
    display: inline-block;
    margin: 0.1em 1em;
    color: rgb(244, 244, 244);
    font-size: 2em;
}

.header-item:hover {
    cursor: pointer;
}

/* Forgive me. Stupid hack to simulate non-moving bold text, font-weight can't do this.*/
.header-item:hover, .active {
    text-decoration: underline;
    text-underline-offset: 0.3em;
    color: rgb(255, 255, 255);
    transform: translateY(-0.1em);
    filter: brightness(110%);
    text-shadow: 0px 0px 20px rgb(255, 255, 255), 5px 5px 5px rgba(0, 0, 0, 0.363), 0px 0px 2px rgb(255, 255, 255), 0px 0px 2px rgb(255, 255, 255), 0px 0px 2px rgb(255, 255, 255), 5px 5px 5px rgba(0, 0, 0, 0.25); 
}