.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0 2.9em 0;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    flex-flow: wrap;
}

.name {
    color: white;
    font-size: 1.5em;
    width: 100%;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
}