.contact-row {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

.contact-row p {
    margin: 0.2em 0;
}

.contact-row svg {
    margin-right: 0.3em;
}

.contact-row:hover svg {
    color: rgb(113, 57, 141);
    transform: rotate(10deg);
    filter: drop-shadow(0.1em 0.1em 0.1em rgba(113, 57, 141, 0.5));
}