canvas {
    width: 100%;
    height: 100%;
}

.background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
}